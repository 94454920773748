const skillsData = [
  {
    name: "Googling",
    level: "95%",
  },
  {
    name: "Drawing",
    level: "55%",
  },
  {
    name: "Coding",
    level: "75%"
  },
  {
    name: "Problem Solving",
    level: "75%",
  },
  {
    name: "Organizing",
    level: "35%"
  },
  {
    name: "Cooking",
    level: "20%"
  }
]

export default skillsData