import htmlcssImg from 'assets/images/htmlcss.webp';
import jsImg from 'assets/images/js.webp';
import reactImg from 'assets/images/react.webp';
import bootstrapImg from 'assets/images/bootstrap.webp';
import cImg from 'assets/images/c.webp';
import cppImg from 'assets/images/cpp.webp';
import firebaseImg from 'assets/images/firebase.webp';
import gwImg from 'assets/images/gw.webp';
import pythonImg from 'assets/images/python.webp';
import muiImg from "assets/images/mui.webp";
import djangoImg from "assets/images/django.webp";
import flaskImg from "assets/images/flask.webp";
import javaImg from "assets/images/java.webp";
import jinjaImg from "assets/images/jinja.webp";
import mysqlImg from "assets/images/mysql.webp";
import nodejsImg from "assets/images/nodejs.webp";
import postgresImg from "assets/images/postgres.webp";
import sqliteImg from "assets/images/sqlite.webp";
import threejsImg from "assets/images/threejs.webp";

const learningsData = [
  {
    abbr: 'html',
    name: 'HTML && CSS',
    img: `${htmlcssImg}`,
    year: '2016',
    full: false
  },
  {
    abbr: 'js',
    name: 'JavaScript',
    img: `${jsImg}`,
    year: '2019',
    full: false
  },
  {
    abbr: 'python',
    name: 'Python',
    img: `${pythonImg}`,
    year: '2019',
    full: false
  },
  {
    abbr: 'bootstrap',
    name: 'Bootstrap',
    img: `${bootstrapImg}`,
    year: '2020',
    full: false
  },
  {
    abbr: 'c',
    name: 'Objective C',
    img: `${cImg}`,
    year: '2021',
    full: false
  },
  {
    abbr: 'gw',
    name: 'Google Workspace Tools',
    img: `${gwImg}`,
    year: '2021',
    full: true
  },
  {
    abbr: 'firebase',
    name: 'Firebase',
    img: `${firebaseImg}`,
    year: '2021',
    full: false
  },
  {
    abbr: 'react',
    name: 'ReactJS',
    img: `${reactImg}`,
    year: '2021',
    full: false
  },
  {
    abbr: 'cpp',
    name: 'C++',
    img: `${cppImg}`,
    year: '2022',
    full: false
  },
  {
    abbr: 'jinja',
    name: 'Jinja',
    img: `${jinjaImg}`,
    year: '2022',
    full: false
  },
  {
    abbr: 'flask',
    name: 'Flask',
    img: `${flaskImg}`,
    year: '2022',
    full: true
  },
  {
    abbr: 'django',
    name: 'Django',
    img: `${djangoImg}`,
    year: '2022',
    full: true
  },
  {
    abbr: 'sqlite',
    name: 'SQLite',
    img: `${sqliteImg}`,
    year: '2022',
    full: true
  },
  {
    abbr: "threejs",
    name: "Three JS",
    img: `${threejsImg}`,
    year: "2022",
    full: false
  },
  {
    abbr: 'java',
    name: 'Java',
    img: `${javaImg}`,
    year: '2022',
    full: false
  },
  {
    abbr: 'postgres',
    name: 'PostgreSQL',
    img: `${postgresImg}`,
    year: '2022',
    full: false
  },
  {
    abbr: 'nodejs',
    name: 'NodeJS',
    img: `${nodejsImg}`,
    year: '2022',
    full: true
  },
  {
    abbr: 'mysql',
    name: 'MySql',
    img: `${mysqlImg}`,
    year: '2022',
    full: true
  },
  {
    abbr: 'mui',
    name: 'Material UI',
    img: `${muiImg}`,
    year: '2022',
    full: false
  },
];

export default learningsData;